import { z, type Schema } from 'zod';

type ObjectName = 'credit';

export const DialogSourceSchema = z
  .enum([
    'creditInfo',
    'noCreditsBanner',
    'askMarleeInput',
    'askMarleePopup',
    'query',
    'dashboardWidget',
  ])
  .optional();
export type CreditDialogSource = z.infer<typeof DialogSourceSchema>;

const creditSchemas = {
  credit_consumed: z.object({
    type: z.enum(['askmarlee', 'assessment']),
    hasUnlimitedCredits: z.boolean(),
    periodicCreditsRemaining: z.number().optional(),
    bonusCreditsRemaining: z.number().optional(),
    totalCreditsRemaining: z.number().optional(),
  }),
  credit_dialogShown: z.object({
    totalCreditsRemaining: z.number(),
    source: DialogSourceSchema,
  }),
  credit_dialogClicked: z.object({
    source: DialogSourceSchema,
    button: z.string(),
  }),
} satisfies { [key: `${ObjectName}_${string}`]: Schema };

export { creditSchemas };
