import { z } from 'zod';

import { type Json } from '../utils';

// TODO finish these zod types
export const DashboardUpdateSchema = z.object({});
export type DashboardUpdate = z.infer<typeof DashboardUpdateSchema>;

export type Dashboard = {
  id: number;
  createDate: Date;
  updateDate: Date;
  userId?: number;
  groupId?: number;
  organizationId?: number;
  layouts: object | null;
  widgets: DashboardWidget[];
};

export type DashboardWidget = {
  id: number;
  createDate: Date;
  updateDate: Date;
  widgetId: number;
  widget: Widget;
  dashboardId: number;
};

export type Widget = {
  id: number;
  createDate: Date;
  updateDate: Date;
  options: object;
  type:
    | 'count'
    | 'distribution'
    | 'top_culture'
    | 'top_affinity'
    | 'top_difference'
    | 'insight'
    | 'motivations'
    | 'motivation_radar'
    | 'user_search'
    | 'user_selection';
  key?: string;
};

export type WidgetInstance = {
  id: number;
  widget: Widget;
  data: Json;
};

export type WidgetLink = {
  id: number;
  widget: WidgetInstance;
  dashboard: Dashboard;
};

export const widgetDataCreateSchema = z
  .object({
    userId: z.number().nullable(),
    modelId: z.number().nullable(),
    workspaceId: z.number().nullable(),
    teamId: z.number().nullable(),
    atDate: z.string().nullable(),
    comparisonType: z.enum(['individual', 'aggregate', 'model']),
    selectionType: z.enum([
      'user',
      'workspace',
      'team',
      'culture',
      'insight',
      'xfactor',
      'role',
    ]),
  })
  .partial();
export type CreateWidgetDataSchema = z.infer<typeof widgetDataCreateSchema>;

const widgetDataSchema = widgetDataCreateSchema
  .extend({
    id: z.number(),
    widgetId: z.number(),
  })
  .partial();

const baseWidgetSchema = z
  .object({
    id: z.number(),
    dashboardId: z.coerce.number(),
    title: z.string(),
    description: z.string().nullable(),
    widgetTemplateId: z.number(),
    motivationGroupId: z.number().nullable(),
    cultureModelId: z.number().nullable(),
    data: z.array(widgetDataSchema),
  })
  .partial();
export const updateWidgetSchema = baseWidgetSchema.extend({
  dashboardId: z.coerce.number(),
});

export const createWidgetSchema = updateWidgetSchema.extend({
  title: z.string(),
});
export type UpdateWidgetSchema = z.infer<typeof updateWidgetSchema>;
export type CreateWidgetSchema = z.infer<typeof createWidgetSchema>;
export type UpdateWidgetDataSchema = z.infer<typeof widgetDataSchema>;
